<template>
  <div id="support-admin-ticket-modal">

    <ErrorPopup :error="error" />

    <b-modal ref="edit-modal" size="xl" :ok-title="$t('COMMON.SAVE')" hide-footer>

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('SUPPORT.TICKET.DETAILS') }}</v-tab>
        <v-tab>{{ $t('SUPPORT.TICKET.MESSAGES') }}</v-tab>

        <v-tab-item>
          <SupportAdminTicketForm
            class="mr-8 mb-8"
            :item="local_item"
            @created="ticket_created"
            @updated="ticket_updated"
            @cancel="()=>{this.$refs['edit-modal'].hide();}"
          />
        </v-tab-item>

        <v-tab-item>

          <SupportAdminTicketMessages
            v-if="local_item && local_item.messages"
            :items="local_item.messages"
            :ticket="local_item"
            style="min-height: 640px;"
          />

        </v-tab-item>

      </v-tabs>


    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import SupportAdminTicketForm from '@/view/pages/ml/support/SupportAdminTicketForm.vue';
import SupportAdminTicketMessages from '@/view/pages/ml/support/SupportAdminTicketMessages.vue';

import ErrorPopup from '@/view/components/ErrorPopup.vue';


export default {
  name: 'SupportAdminTicketModal',
  components: {
    SupportAdminTicketForm,
    SupportAdminTicketMessages,
    ErrorPopup
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

  },
  props: ['item'],
  emits: ['created', 'updated'],
  async mounted() {
    if (this.item) {
      this.local_item = { ...this.item }
    }
  },
  methods: {

    show() {
      this.$refs['edit-modal'].show();

      this.$nextTick(() => {

      });
    },

    hide() {
      this.$refs['edit-modal'].hide();

      this.$nextTick(() => {

      });
    },

    ticket_created(item) {
      this.$emit('created', item);

      this.hide();

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SUPPORT.TICKET.CREATED'));
    },

    ticket_updated(item) {
      this.$emit('updated', item);

      this.hide();

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SUPPORT.TICKET.UPDATED'));
    },

    edit_user_clicked(item) {
      this.local_item = { ...item };
      this.$refs['edit-modal'].show();
    },

    new_user_clicked() {
      this.local_item = {};
      this.$refs['edit-modal'].show();
    },

  },
  data() {
    return {
      error: null,

      local_item: null

    };
  }

};
</script>
<style lang="scss" scoped>
:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
