<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('SUPPORT.TICKET.CREATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('COMPANY.COMPANY')">

              <b-form-select
                v-model="local_item.company_id"
                :options="company_options"
                :state="validate_state({ dirty: v$['local_item']['company_id'].$dirty, error: v$['local_item']['company_id'].$error })">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.NAME')">

              <b-form-input v-model="local_item.name" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('MEMBER.EMAIL')">

              <b-form-input v-model="local_item.email" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('SUPPORT.IS_THS')">

              <div class="d-flex">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input type="checkbox" name="" v-model="local_item.is_ths" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{$t('SUPPORT.IS_THS')}}</span>
              </div>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('SUPPORT.IS_DEDICATED')">

              <div class="d-flex">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input type="checkbox" name="" v-model="local_item.is_dedicated" />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{$t('SUPPORT.IS_DEDICATED')}}</span>
              </div>

            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons :text="$t('COMMON.SAVE')" :spin="true" @clicked="on_submit"
              @close="$emit('cancel')" ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'SupportAdminEmailForm',

  props: ['item'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [toasts],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        company_id: { required },
        assigned_user_id: { required }
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

  },


  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));

  },

  methods: {

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      if (!this.local_item.id) {
        await this.create_email();
      }
      else {
        await this.update_email();
      }

      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async create_email() {
      try {
        const res = await axios.post(`/support/admin/company/email`, this.local_item);

        this.local_item = res.data;

        if (res.status === 201) {
          this.$emit('created', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('create_email error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
    },

    async update_email() {
      try {
        const res = await axios.put(`/support/admin/company/email/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('update_ticket error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
    }

  },


  data() {
    return {

      local_item: {},

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      company_options: [],

      user_options: [],

      users: [],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
