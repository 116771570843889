<template>
  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column pl-2">

      </h3>
      <!--<div class="card-toolbar">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-1" @click.prevent="create_ticket_clicked"><i
              class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SUPPORT.TICKET.CREATE') }}</a>
        </div>-->
    </div>

    <div class="card-body pt-0 table-responsive">
      <b-table 
        id="stickprov-table" 
        :fields="headers" 
        :items="items" 
        head-variant="light"
        class='table-striped w-100 mh-100' 
        sticky-header>
        <template #cell(action)='row'>
          <div class='d-flex justify-content-end'>
            <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click.prevent="select_email_clicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">

                <inline-svg src="/assets/svg/Write.svg"></inline-svg>

              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_email_clicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">

                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>

              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
    
<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'SupportAdminEmailsTable',
  mixins: [ toasts ],
  props: ['items'],
  emits: ['select_email_clicked', 'delete_email_clicked'],
  components: {

  },
  methods: {

    select_email_clicked(item) {
      this.$emit('select_email_clicked', item);
    },
    delete_email_clicked(item) {
      this.$emit('delete_email_clicked', item);
    },

  },
  data() {
    return {
      headers: [
        {
          key: 'created_at',
          label: this.$t('SUPPORT.TICKET.CREATED_AT'),
          sortable: true,
          thClass: 'td-short',
          tdClass: 'td-short align-middle'
        },
        {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'company_id',
          label: this.$t('COMPANY.COMPANY'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            return item.company === null ? this.$t('SUPPORT.TICKET.UNASSIGNED') : item.company.name;
          }
        },
        {
          key: 'action',
          label: '',
          thClass: 'w-110px',
          tdClass: 'align-middle'
        },
      ],
      list: [

      ]
    };
  }
};
</script>
    