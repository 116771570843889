<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8 mr-8">

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.CREATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.MESSAGE.SEND_AT')">


              <memlist-date-picker
                v-model="send_at_date"
                :disabled="is_disabled"
              >
              </memlist-date-picker>

              <memlist-time-picker
                class="mt-2"
                v-model="send_at_time"
                :disabled="is_disabled"
              >
              </memlist-time-picker>


            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.MESSAGE.STATUS')">

              <b-form-select
                v-model="local_item.status"
                :options="status_options"
                :disabled="is_disabled"
                :state="validate_state({ dirty: v$['local_item']['company_id'].$dirty, error: v$['local_item']['company_id'].$error })">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group id="input-group-reply_text" :label="$t('SUPPORT.TICKET.REPLY_TEXT')" label-for="input-reply_text">

              <tiptap-vuetify
                data-app
                v-model="html_message"
                :disabled="is_disabled"
                :extensions="extensions"
              />
              
            </b-form-group>
          </b-col>
        </b-row>

        <RightModalSaveAndCloseButtons
          class="mt-4"
          :text="$t('COMMON.SAVE')"
          :spin="true"
          @clicked="on_submit"
          @close="$emit('cancel')"
          ref="saveButton"
        />

      </b-form>
    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'SupportAdminTicketMessage',

  props: ['item','ticket'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    TiptapVuetify
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
        this.set_read_by();
        this.set_html_message();
        this.set_date_and_time();
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        company_id: { required },
        assigned_user_id: { required }
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    header() {

      if (!this.local_item || !this.local_item.message) { return ''; }

      return this.local_item.message.replace(/(?:\r\n|\r|\n)/g, ' ').substring(0, 92);
    },

    is_disabled() {

      if (!this.local_item) {
        return true;
      }

      if (this.local_item.sent_by_ths === false) {
        return true;
      }

      // if the message will be sent in the future, we allow to be changed
      if (this.local_item.sent_by_ths && dayjs(this.local_item.send_at) < dayjs()) {
        return true;
      }

      return false;
    }
  },


  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item };

      this.set_date_and_time();
      this.set_html_message();
      this.set_read_by();
    }

    this.users = await this.get_users();

    this.user_options = this.users.map((item) => ({ text: item.email, value: item.user_id }));
    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));

  },

  methods: {

    set_date_and_time() {
      if (!this.local_item || !this.local_item.send_at) {
        this.send_at_date = dayjs().format('YYYY-MM-DD');
        this.send_at_time = dayjs().add(30, 'minutes').format('HH:mm');
        return;
      }

      const date = dayjs(this.local_item.send_at).format('YYYY-MM-DD');
      const time = dayjs(this.local_item.send_at).format('HH:mm');

      this.send_at_date = date;
      this.send_at_time = time;
    },

    set_html_message() {
      if (!this.local_item || !this.local_item.message) {
        this.html_message = '';
        return;
      }

      this.html_message = this.local_item.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },

    async set_read_by() {
      try {
        if (!this.local_item || !this.local_item.id) {
          return;
        }

        const res = await axios.post(`/support/admin/ticket/message/${this.local_item.id}/read`);

        if (res.status === 200) {
          this.local_item.read_by = res.data.read_by;
          return;
        }

        if (res.status === 204) {
          // already read
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SUPPORT.MESSAGE.UNABLE_SET_READ'));
    },


    async get_users() {
      try {
        const res = await axios.get(`/access/users`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }

      return [];
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {


      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async create_message() {
      try {
        const res = await axios.post(`/support/admin/ticket/${this.ticket.id}/message`, this.local_item);

        if (res.status === 201) {
          this.local_item = { ...res.data };

          this.$emit('created', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('create_ticket error', err);
      }
    },

    async update_message() {
      try {
        const res = await axios.put(`/support/admin/ticket/message/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('update_ticket error', err);
      }
    }

  },


  data() {
    return {

      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      send_at_date: null,
      send_at_time: null,

      html_message: '',

      local_item: {},

      user_options: [],

      users: [],

      status_options: [
        { value: 'NONE', text: this.$t('SUPPORT.MESSAGE.STATUSES.NONE') },
        { value: 'UNREAD', text: this.$t('SUPPORT.MESSAGE.STATUSES.UNREAD') },
        { value: 'PENDING_SEND', text: this.$t('SUPPORT.MESSAGE.STATUSES.PENDING_SEND') },
        { value: 'UNHANDLED', text: this.$t('SUPPORT.MESSAGE.STATUSES.UNHANDLED') },
        { value: 'REPLIED', text: this.$t('SUPPORT.MESSAGE.STATUSES.REPLIED') },
        { value: 'DELETED', text: this.$t('SUPPORT.MESSAGE.STATUSES.DELETED') },
      ],


    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.action-frame {
  float: left;
}

.date-frame {
  border: 1px solid #00a0c5;
  padding: 6px;
  border-radius: 6px;
  background-color: #e0faff;
  margin-right: 13px;
}

.frame-container {
  clear: both;
  height: 64px;
}

.frame-header {
  border: 1px solid #d2d2d2;
  padding: 16px;
  margin-right: 16px;
  border-radius: 8px;
  width: 920px;
  float: left;
}

.frame-header:hover {
  cursor: pointer;
  background-color: #b8e9f4;
}

.frame-message {
  border: 1px solid #d2d2d2;
  padding: 16px;
  margin-right: 16px;
  border-radius: 8px;
}
</style>
