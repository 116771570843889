<template>
  <div>

    <SupportAdminTicketMessageModal
      ref="message-modal"
      :item="selected_item"
    />

    <div class="d-flex align-items-end flex-column">
      <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-1"
        @click.prevent="create_message_clicked">
        <i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('SUPPORT.MESSAGE.CREATE') }}</a>
    </div>


    <b-table id="message-table"
      :fields="fields"
      :items="items"
      head-variant="light"
      class='table-striped w-100 mh-100 mt-4'
      sticky-header>
      <template #cell(sent_by_user_id)="row">
        <v-chip
          style="font-size: 10px;"
          v-if="row.item.sent_by_user === null && row.item.sent_by_email === null"
          color="red"
          outlined
        >
          {{ $t('COMMON.ERROR') }}
        </v-chip>

        <v-chip
          style="font-size: 10px;"
          v-if="row.item.sent_by_user !== null"
          color="green"
          outlined
        >
          {{ row.item.sent_by_user.email }}
        </v-chip>

        <v-chip
          style="font-size: 10px;"
          v-if="row.item.sent_by_email !== null"
          color="orange"
          outlined
        >
          {{ row.item.sent_by_email }}
        </v-chip>

      </template>
      <template #cell(action)='row'>
        <div class='d-flex justify-content-end'>
          <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click.prevent="select_message_clicked(row.item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">

              <inline-svg src="/assets/svg/Write.svg"></inline-svg>

            </span>
          </a>
          <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_message_clicked(row.item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">

              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>

            </span>
          </a>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


import ErrorPopup from '@/view/components/ErrorPopup.vue';

import SupportAdminTicketMessageModal from '@/view/pages/ml/support/SupportAdminTicketMessageModal.vue';

export default {
  name: 'SupportAdminTicketMessagesTable',
  components: {
    SupportAdminTicketMessageModal,
    ErrorPopup
  },
  watch: {
    items: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_items = [ ...this.items ];
      }
    }
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

  },
  props: ['items'],
  emits: ['deleted'],
  async mounted() {
    if (this.items) {
      this.local_items = [ ...this.items ]
    }
  },
  methods: {
    create_message_clicked() {
      this.selected_item = {};
      this.$refs['message-modal'].show();
    },

    select_message_clicked(item) {
      this.selected_item = item;
      this.$refs['message-modal'].show();
    },

    delete_message_clicked(item) {
      this.delete_message(item.id);
    },

    async delete_message(id) {
      try {
        const res = await axios.delete(`/support/admin/message/${id}`);

        if (res.status === 204) {
          this.$emit('deleted', id);
          return;
        }
      }
      catch (err) {
        console.error('delete_message error', err);
      }
    }
  },
  data() {
    return {
      selected_item: null,

      error: null,

      local_item: null,

      fields: [
        {
          key: 'send_at',
          label: this.$t('SUPPORT.MESSAGE.SEND_AT'),
          sortable: true,
          thClass: 'td-short',
          tdClass: 'td-short align-middle'
        },
        {
          key: 'message',
          label: this.$t('SUPPORT.MESSAGE.MESSAGE'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle td-ellipsis-wide ',
          formatter: (_, __, item) => {
            return item.message !== null ? item.message.replace(/(?:\r\n|\r|\n)/g, ' ').substring(0, 80) : 'Tomt meddelande';
          }
        },
        {
          key: 'sent_by_user_id',
          label: this.$t('SUPPORT.MESSAGE.SENT_BY'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle',
          /*formatter: (_, __, item) => {
            return item.assigned_user_id === null ? this.$t('SUPPORT.TICKET.UNASSIGNED') : item.assigned_user.email;
          }*/
        },
        {
          key: 'action',
          label: '',
          thClass: 'w-110px',
          tdClass: 'align-middle'
        },
      ],
    };
  }

};
</script>
<style lang="scss" scoped>
:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .td-ellipsis-wide {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
