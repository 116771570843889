<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.CREATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="local_item.messages && local_item.messages.length > 0">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.ORIGINAL_SENDER')">

              <b-form-input :disabled="true" v-model="local_item.messages[0].sent_by_email" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('COMPANY.COMPANY')">

              <b-form-select v-model="local_item.company_id" :options="company_options"
                :state="validate_state({ dirty: v$['local_item']['company_id'].$dirty, error: v$['local_item']['company_id'].$error })">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.ASSIGNED_USER')">

              <b-form-select v-model="local_item.assigned_user_id" :options="user_options"
                :state="validate_state({ dirty: v$['local_item']['assigned_user_id'].$dirty, error: v$['local_item']['assigned_user_id'].$error })">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.NAME')">

              <b-form-input v-model="local_item.name" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.STATUS')">

              <b-form-select
                v-model="local_item.status"
                :options="status_options"
                :state="validate_state({ dirty: v$['local_item']['status'].$dirty, error: v$['local_item']['status'].$error })">
              </b-form-select>


            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.TYPE')">

              <b-form-select
                v-model="local_item.type"
                :options="type_options"
                :state="validate_state({ dirty: v$['local_item']['type'].$dirty, error: v$['local_item']['type'].$error })">
              </b-form-select>


            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SUPPORT.TICKET.DESCRIPTION')">

              <b-textarea rows="8" v-model="local_item.description" />

            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons :text="$t('COMMON.SAVE')" :spin="true" @clicked="on_submit"
              @close="$emit('cancel')" ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'SupportAdminTicketForm',

  props: ['item'],
  emits: ['created', 'updated', 'save_data', 'cancel'],

  mixins: [toasts],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        company_id: { required },
        assigned_user_id: { required },
        status: { required },
        type: { required },
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    company_options() {
      if (!this.companies) {
        return [];
      }

      const comps = this.companies.map((item) => ({ text: (item.shortname && item.shortname.length > 0 ? item.shortname : item.name), value: item.company_id }));

      comps.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }

        return 1;
      });

      return comps;
    }
  },


  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    this.users = await this.get_users();

    this.user_options = this.users.map((item) => ({ text: item.email, value: item.user_id }));

  },

  methods: {

    async get_users() {
      try {
        const res = await axios.get(`/access/users`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }

      return [];
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      if (!this.local_item.id) {
        await this.create_ticket();
      }
      else {
        await this.update_ticket();
      }

      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async create_ticket() {
      try {
        const res = await axios.post(`/support/admin/ticket`, this.local_item);

        if (res.status === 201) {
          this.local_item = { ...res.data };

          this.$emit('created', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('create_ticket error', err);
      }
    },

    async update_ticket() {
      try {
        const res = await axios.put(`/support/admin/ticket/${this.local_item.id}`, this.local_item);

        if (res.status === 204) {
          this.$emit('updated', this.local_item);

          return;
        }

      }
      catch (err) {
        console.error('update_ticket error', err);
      }
    }

  },


  data() {
    return {

      local_item: {},

      status_options: [
        { value: 'NONE', text: this.$t('SUPPORT.TICKET.STATUSES.NONE') },
        { value: 'UNHANDLED', text: this.$t('SUPPORT.TICKET.STATUSES.UNHANDLED') },
        { value: 'TODO', text: this.$t('SUPPORT.TICKET.STATUSES.TODO') },
        { value: 'REPLY_SENT', text: this.$t('SUPPORT.TICKET.STATUSES.REPLY_SENT') },
        { value: 'NEW_MESSAGE', text: this.$t('SUPPORT.TICKET.STATUSES.NEW_MESSAGE') },
        { value: 'RESOLVED', text: this.$t('SUPPORT.TICKET.STATUSES.RESOLVED') },
        { value: 'CLOSED', text: this.$t('SUPPORT.TICKET.STATUSES.CLOSED') },
        { value: 'TENTATIVE', text: this.$t('SUPPORT.TICKET.STATUSES.TENTATIVE') }
      ],

      type_options: [
        { value: 'UNDETERMINED', text: this.$t('SUPPORT.TICKET.TYPES.UNDETERMINED') },
        { value: 'SALES_CONTACT', text: this.$t('SUPPORT.TICKET.TYPES.SALES_CONTACT') },
        { value: 'BUGREPORT', text: this.$t('SUPPORT.TICKET.TYPES.BUGREPORT') },
        { value: 'EDUCATION', text: this.$t('SUPPORT.TICKET.TYPES.EDUCATION') },
        { value: 'SUGGESTION', text: this.$t('SUPPORT.TICKET.TYPES.SUGGESTION') },
        { value: 'INQUIRY', text: this.$t('SUPPORT.TICKET.TYPES.INQUIRY') }
      ],

      user_options: [],

      users: [],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
