<template>
  <div id="support-admin-ticket-modal">

    <ErrorPopup :error="error" />

    <b-modal ref="edit-modal" size="lg" :ok-title="$t('COMMON.SAVE')" hide-footer>
      <SupportAdminEmailForm
        :item="local_item"
        @created="email_created"
        @updated="email_updated"
        @cancel="()=>{this.$refs['edit-modal'].hide();}"
      />
    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import SupportAdminEmailForm from '@/view/pages/ml/support/SupportAdminEmailForm.vue'
import ErrorPopup from '@/view/components/ErrorPopup.vue';


export default {
  name: 'SupportAdminEmailModal',
  components: {
    SupportAdminEmailForm,
    ErrorPopup
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

  },
  props: ['item'],
  emits: ['created', 'updated'],
  async mounted() {
    if (this.item) {
      this.local_item = { ...this.item }
    }
  },
  methods: {

    show() {
      this.$refs['edit-modal'].show();

      this.$nextTick(() => {

      });
    },

    hide() {
      this.$refs['edit-modal'].hide();

      this.$nextTick(() => {

      });
    },

    email_created(item) {
      this.$emit('created', item);

      this.$refs['edit-modal'].hide();

      this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.CREATED'));
    },

    email_updated(item) {
      this.$emit('updated', item);

      this.$refs['edit-modal'].hide();

      this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
    },


  },
  data() {
    return {
      error: null,

      local_item: null

    };
  }

};
</script>
<style lang="scss" scoped>
:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
