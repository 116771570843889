<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <SupportAdminTicketMessagesTable
        :items="local_items"
      />


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import SupportAdminTicketMessagesTable from '@/view/pages/ml/support/SupportAdminTicketMessagesTable.vue';

export default {

  name: 'SupportAdminTicketMessages',

  props: ['items','ticket'],
  emits: ['save_data'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    SupportAdminTicketMessagesTable
  },

  watch: {
    items: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_items = [ ...this.items ];
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {
        company_id: { required },
        assigned_user_id: { required }
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

  },


  async mounted() {

    if (this.items) {
      this.local_items = [ ...this.items ];
    }

  },

  methods: {

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {


      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

  },


  data() {
    return {

      reply_text: null,

      local_items: [],

      local_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.frame-message {
  border: 1px solid #d2d2d2;
  padding: 16px;
  margin-right: 16px;
  border-radius: 8px;
}
</style>
