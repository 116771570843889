<template>
  <div id="support-admin-summary-chart">

    <ErrorPopup :error="error" />

    <CompatibleBarChart
      :options="bar_option"
      :chartData="chart_data"
    />

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import CompatibleBarChart from '@/view/components/charts/CompatibleBarChart.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import dayjs from 'dayjs';


export default {
  name: 'SupportAdminSummaryChart',
  components: {
    CompatibleBarChart,
    ErrorPopup
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

    month_labels() {
      const start_month = dayjs().subtract(12, 'months');
      let next_month = start_month;

      const labels = [
        this.$t('CALENDAR.SHORT_MONTHS.' + this.get_month_short_str(start_month.month()))
      ];

      for (let i = 0; i < 12; ++i) {
        next_month = next_month.add(1, 'month');

        labels.push(
          this.$t('CALENDAR.SHORT_MONTHS.' + this.get_month_short_str(next_month.month()))
        );

      }

      return labels;
    },

    chart_data() {
      return {
        is_loading: false,
        data_loaded: false,
        labels: this.month_labels,
        datasets: [
          {
            label: 'Kvinnor',
            backgroundColor: '#5d78ff',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: []
          },
          {
            label: 'Män',
            backgroundColor: '#93a2dd',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: []
          },
          {
            label: 'Övriga',
            backgroundColor: '#646c9a',
            borderWidth: 0,
            categoryPercentage: 0.35,
            barPercentage: 0.7,
            data: []
          }
        ]
      };
    }
  },
  props: ['item'],
  async mounted() {
    if (this.item) {
      this.local_item = { ...this.item }
    }
  },
  methods: {
    get_month_short_str(index) {
      return [
        'JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'
      ][index];
    }
  },
  data() {
    return {
      error: null,

      local_item: null,

      bar_option: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        hover: {
          mode: 'index'
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }

    };
  }

};
</script>
<style lang="scss" scoped>
:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
