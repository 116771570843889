<template>
  <div id="support-admin-page">
    
    <SupportAdminTicketModal
      ref="edit-modal"
      :item="selected_ticket"
      @created="ticket_created"
      @updated="ticket_updated"
    />

    <SupportAdminEmailModal
      ref="edit-email-modal"
      :item="selected_email"
      @created="email_created"
      @updated="email_updated"
    />

    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{ $t('SUPPORT.UNHANDLED') }}</v-tab>
      <v-tab>{{ $t('SUPPORT.ARCHIVE') }}</v-tab>
      <v-tab>{{ $t('SUPPORT.EMAILS') }}</v-tab>
      <v-tab>{{ $t('SUPPORT.SUMMARY') }}</v-tab>
      
      <v-tab-item class="pt-8">
        <SupportAdminTicketsTable 
          :items="unhandled_tickets"
          @create_ticket_clicked="create_ticket_clicked"
          @select_ticket_clicked="select_ticket_clicked"
          @delete_ticket_clicked="delete_ticket_clicked"
        />

      </v-tab-item>

      <v-tab-item class="pt-8">

      </v-tab-item>


      <v-tab-item class="pt-8">
        
        <SupportAdminEmailsTable 
          :items="emails"
          @create_email_clicked="create_email_clicked"
          @select_email_clicked="select_email_clicked"
        />
      </v-tab-item>

      <v-tab-item class="pt-8">
        <SupportAdminSummaryChart />
      </v-tab-item>

      
    </v-tabs>

    <div id="wiztr-bottom"></div>

  </div>
</template>
  
<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import SupportAdminTicketsTable from '@/view/pages/ml/support/SupportAdminTicketsTable.vue';
import SupportAdminEmailsTable from '@/view/pages/ml/support/SupportAdminEmailsTable.vue';
import SupportAdminTicketModal from '@/view/pages/ml/support/SupportAdminTicketModal.vue';
import SupportAdminEmailModal from '@/view/pages/ml/support/SupportAdminEmailModal.vue';
import SupportAdminSummaryChart from '@/view/pages/ml/support/SupportAdminSummaryChart.vue';



export default {
  name: 'SupportAdminPage',
  mixins: [ toasts ],
  components: {
    SupportAdminTicketsTable,
    SupportAdminTicketModal,
    SupportAdminEmailsTable,
    SupportAdminEmailModal,
    SupportAdminSummaryChart
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'isTHS'])
  },
  async mounted() {
    this.unhandled_tickets = await this.load_tickets();
    this.emails = await this.load_emails();
  },
  data() {
    return {
      timepicked: null,
      unhandled_tickets: [],

      emails: [],

      selected_ticket: null,

      selected_email: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    email_created(email) {
      this.emails.push(email);
    },

    email_updated(email) {
      const index = this.emails.findIndex(item => item.id === email.id);

      if (index >= 0) {
        this.emails[index] = email;

        this.emails = [ ...this.emails ];
      }
    },

    ticket_created(item) {
      this.unhandled_tickets.push(item);
    },

    async ticket_updated(item) {
      this.unhandled_tickets = await this.load_tickets();
    },

    create_email_clicked() {},

    create_ticket_clicked() {
      this.selected_ticket = {};

      this.$refs['edit-modal'].show();
    },

    async delete_ticket_clicked(item) {
      console.log('delete clicked')
      try {
        const res = await axios.delete(`/support/admin/ticket/${item.id}`);

        if (res.status === 204) {

          this.unhandled_tickets = this.unhandled_tickets.filter(i => i.id !== item.id);

          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SUPPORT.TICKET.DELETED'));

          return true;
        }
      }
      catch (err) {
        console.error('delete_ticket_clicked error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SUPPORT.TICKET.UNABLE_DELETE'));

      return false;
    },

    async select_email_clicked(item) {
      this.selected_email = await this.get_email(item.id);
      
      this.$refs['edit-email-modal'].show();
    },

    async select_ticket_clicked(item) {
      this.selected_ticket = await this.get_ticket(item.id);
      
      this.$refs['edit-modal'].show();
    },

    async get_ticket(id) {
      try {
        const res = await axios.get(`/support/admin/ticket/${id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_ticket error', err);
      }
    },


    async get_email(id) {
      try {
        const res = await axios.get(`/support/admin/company/email/${id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_email error', err);
      }
    },

    async load_tickets() {
      try {
        const res = await axios.get(`/support/admin/tickets`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('load_tickets error', err);
      }

      return [];
    },

    async load_emails() {
      try {
        const res = await axios.get(`/support/admin/company/emails`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('load_tickets error', err);
      }

      return [];
    }
  }
};
</script>
  